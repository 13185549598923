<template>
<ul class="user-menu level-0" :style="style" :class="{ 'm-0': !this.isUserInternalAdmin && !this.isUserCEAdmin }">
    <li class="item" v-if="authenticated">
        <span class="chevron collapsible" :class="{ open: isOpen }" @click="toggleDropdown()">
            {{ fullName }}
        </span>
        <div>
            <ul class="level-1">
                <li @click="handleLinkClick()" class="item" v-for="(item, i) in filteredMenuItems" :key="i">
                    <span v-if="item.text == 'Sign out'" @click="logout()" class="pointer-on-hover">
                        {{ item.text }}
                    </span>
                    <span class="pointer-on-hover" v-else @click="navigateToPage(item.navigationScenario)">
                        {{ item.text }}
                    </span>
                </li>
            </ul>
        </div>
    </li>
</ul>
</template>

<script>
import {
    navigationScenarioConstants,
    globalValueConsts,
    OIDC
} from "@/constants";
import globalMethods from "@/global-methods.js";
import {
    EventBus
} from "@/event-bus.js";
import { useSfaStore } from "@/stores/sfa.js"
import { useUserStore } from "@/stores/user.js"
import { useContentStore } from "@/stores/content.js"
import { useClaimStore } from "@/stores/claim.js"

export default {
    name: "standard-header-user-menu",
    setup() {
        const sfaStore = useSfaStore();
        const userStore = useUserStore();
        const contentStore = useContentStore();
        const claimStore = useClaimStore();
        return { sfaStore, userStore, contentStore,claimStore };
    },
    props: {
        isHamburgerMenuOpen: Boolean,
        isExploreTopicsMenuOpen: Boolean,
        initialTop: Number
    },
    emits: ['user-menu-link-clicked'],
    computed: {
        fullName() {
            let fullName = "";
            if (this.authenticated) {
                fullName = this.claims.name == undefined ? "" : this.claims.name;
            }
            return fullName;
        },
        filteredMenuItems() {
            let filteredMenuItems = [];
            if (this.menuItems && this.authenticated) {
                filteredMenuItems = this.menuItems.filter(
                    (item) => {
                        if (this.isCobranded) {
                            if (this.isSafeliteAgent) {
                                return !item.HideForcobranded && !item.isAdminOnly;
                            }
                            return !item.HideForcobranded;
                        } else if (this.isUserAdmin) {
                            return true;
                        } else if (this.isUserInternalAdmin) {
                            return item.shouldShowForInternalAdmin;
                        } else if (this.isUserCEAdmin) {
                            return item.shouldShowForCEAdmin;
                        } else {
                            return !item.isAdminOnly
                        }

                    });
            }
            return filteredMenuItems;
        },
        style() {
            const initialStartingPositionFromTop = this.initialTop - 20;
            let topValue;

            if (window.innerWidth <= globalValueConsts.MOBILE_BREAKPOINT) {
                if (this.isHamburgerMenuOpen) {
                    if (this.isExploreTopicsMenuOpen) {
                        topValue = initialStartingPositionFromTop + 20 + this.numberOfTags * 40 + 15;
                    } else {
                        topValue = initialStartingPositionFromTop + 20;
                    }
                } else {
                    if (this.isExploreTopicsMenuOpen) {
                        topValue = initialStartingPositionFromTop + this.numberOfTags * 40 + 15;
                    } else {
                        topValue = initialStartingPositionFromTop;
                    }
                }
            }

            return {
                '--top-value': topValue + 'px'
            };
        },
        numberOfTags() {
            return Object.keys(this.contentStore.tags).length;
        },
        isCobranded() {
            return this.sfaStore.isCobranded;
        }
    },
    data() {
        return {
            isOpen: false,
            isUserInternalAdmin: false,
            isUserCEAdmin: false,
            menuItems: [{
                    text: "Manage my users",
                    navigationScenario: navigationScenarioConstants.NAVIGATE_TO_MANAGE_USERS,
                    target: "_blank",
                    isAdminOnly: true,
                    shouldShowForInternalAdmin: false,
                    shouldShowForCEAdmin: false
                },
                {
                    text: "Manage my account",
                    navigationScenario: navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS,
                    target: "_blank",
                    shouldShowForInternalAdmin: false,
                    shouldShowForCEAdmin: false
                },
                {
                    text: "Get a quote",
                    navigationScenario: navigationScenarioConstants.NAVIGATE_TO_POLICY_HOLDER_QUOTE,
                    target: "_self",
                    HideForcobranded: true,
                    shouldShowForInternalAdmin: false,
                    shouldShowForCEAdmin: false
                },
                {
                    text: "Sign out",
                    target: "_blank",
                    shouldShowForInternalAdmin: true,
                    shouldShowForCEAdmin: true
                },
            ],
            authenticated: false,
            claims: "",
            isUserAdmin: false,
            isSafeliteAgent: false

        };
    },
    created() {
        this.isAuthenticated();
        this.$auth?.authStateManager.subscribe(this.isAuthenticated);
    },
    watch: {
        // Everytime the route changes, check for auth status
        $route: "isAuthenticated",
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        closeMenu(e) {
            if (!this.$el.contains(e.target)) {
                this.isOpen = false;
            }
        },
        handleLinkClick() {
            this.toggleDropdown();
            this.$emit("user-menu-link-clicked");
        },
        navigateToPage(navigationScenario) {
            if (navigationScenario == navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS) {
                let self = this;
                this.$router.navigate(
                    navigationScenario, {
                        userId: self.claims.sub
                    }
                );
            } else {
                this.$router.navigate(
                    navigationScenario,
                );
            }
        },
        async isAuthenticated() {
            this.authenticated = await this.$auth?.isAuthenticated();
            this.setup();
        },
        async logout() {
            globalMethods.showWaitingModal(true);
            this.$cookies.remove("clientTag");
            this.sfaStore.clientTagInfo.tagId = "";
            if(this.userStore.activeAgencyNumber != null) {
                this.userStore.activeAgencyNumber = null;
                this.userStore.defaultAgencyNumber = null;
                this.userStore.agencyList = [];
            }
            if(this.claimStore.insuranceClaimDetails.carrier != null)
            {
                this.claimStore.insuranceClaimDetails.carrier = {};
            }
            if (this.userStore.userAccountsCount != null) {
                this.userStore.userAccountsCount = 1;
            }
            if(this)
            await this.$auth.signOut({
                postLogoutRedirectUri: OIDC.POST_LOGOUT_REDIRECT_URI,
            });
            this.authenticated = false;
        },
        async setup() {
            if (this.authenticated) {
                this.claims = await this.$auth.getUser();
                this.isUserAdmin = this.claims.dashboardUserRole ?
                    this.claims.dashboardUserRole.includes(OIDC.ADMIN_USER_ROLE) :
                    false;
                this.isSafeliteAgent = this.claims.dashboardUserRole ?
                    this.claims.dashboardUserRole.includes("SFAD_Agent") : false;
                this.isUserInternalAdmin = this.claims.dashboardUserRole ? this.claims.dashboardUserRole.includes(OIDC.INTERNAL_ADMIN_ROLE) : false;
                this.isUserCEAdmin = this.claims.dashboardUserRole ? this.claims.dashboardUserRole.includes(OIDC.CE_ADMIN_ROLE) : false;
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeMenu);
    },
};
</script>

<style lang="scss" scoped>
.user-menu:focus {
    outline: none;
}

li.item {
    display: block;

    >.chevron {
        font-weight: $font-weight-bold;
        color: $blue;
        padding-right: 25px;

        &::before {
            border-color: $blue;
        }
    }

    .pointer-on-hover:hover {
        cursor: pointer;
    }
}

@include tablet {
    .user-menu {
        display: flex;
        align-items: center;
        padding-left: $spacer-five;

        li.item {
            position: relative;

            .chevron {
                +div {
                    position: absolute;
                    z-index: 50;

                    .level-1 {
                        transition: 0.1s opacity ease-out 0.17s,
                            0.2s top cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.05s;
                        opacity: 0;
                        top: -10px;
                    }
                }

                &.open {
                    +div {
                        background-color: $grayscale-eight;
                        width: 250px;
                        right: 0;
                        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
                        padding-bottom: $spacer-three;
                        padding-left: $spacer-six;
                        height: auto;
                        max-height: none;

                        .level-1 {
                            opacity: 1;
                            top: 0px;

                            .item {
                                padding-top: $spacer-four;

                                span,
                                a {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include wide-mobile {
    .nav-open {
        .user-menu {
            opacity: 1;
            pointer-events: unset;
        }

        .m-0 {
            margin-top: 0px !important;
        }
    }

    ul.user-menu {
        @import "@/styles/navigation/chevron.scss";
        pointer-events: none;
        position: absolute;
        top: var(--top-value);
        left: 0;
        z-index: 1;
        width: 100vw;
        opacity: 0;
        transition: 0.1s opacity ease-out 0.17s,
            0.2s top linear 0.0s;

        .chevron {
            @include chevron(12px, 2px, $blue, $grayscale-seven, 0px);

            &.open {
                @include chevron-open(12px, 2px, $blue, $grayscale-seven, 0px);
                margin-bottom: $spacer-five;

                +div {
                    li {
                        &:last-child {
                            padding-bottom: $spacer-five;
                        }
                    }
                }
            }

            &:not(.open) {
                +div {
                    li {
                        padding: 0;
                    }

                    a,
                    span {
                        display: none;
                    }
                }
            }
        }

        li {
            &.item {
                background-color: $grayscale-seven;
            }
        }

        &.level-0 {
            background-color: $grayscale-seven;

            >.item {
                padding: $spacer-five $spacer-four;
            }
        }

        .level-1 {
            >li {
                padding: 0px $spacer-five $spacer-four;
                width: 100vw;
                position: relative;
                left: -$spacer-four;
                opacity: 0;
            }

            width: 100%;
            position: relative;
        }
    }
}
</style>
