<template>
<label @click="toggleHamburgerMenu()">
    <span class="toggle-words"></span>
    <span class="toggle-lines">
        <span></span>
        <span></span>
        <span></span>
    </span>
</label>
</template>

<script>
export default {
    name: "standard-header-hamburger-menu",
    emits: ['hamburger-menu-toggled'],
    methods: {
        toggleHamburgerMenu() {
            this.$emit("hamburger-menu-toggled");
        },
    },
};
</script>

<style lang="scss" scoped>
@include wide-mobile {
    .site-nav {
        label {
            transform: translateZ(0px);
            width: 80px;
            text-align: right;
            line-height: 20px;

            >.toggle-words {

                &::before,
                &::after {
                    content: "Menu";
                    position: absolute;
                    right: 25px;
                    top: 3px;
                    width: 60px;
                    font-size: 14px;
                    color: $blue;
                    text-transform: uppercase;
                    font-weight: $font-weight-bold;
                    transition: $transition-length * 0.6 opacity ease-in $transition-length * 0.4;
                }
            }

            >.toggle-lines {
                display: inline-block;
                vertical-align: middle;

                >span {
                    width: 20px;
                    display: block;
                    height: 2px;
                    background: $blue;
                    margin: 2px 0 0;
                    position: relative;
                    transition: $transition-length all ease-in-out;
                    top: 0;
                    transform: rotate(0deg);
                    transform-origin: center;
                }
            }
        }
    }

    .nav-open {
        label {
            .toggle-words {
                &::after {
                    content: "Close";
                    opacity: 0;
                    transition: $transition-length * 0.6 opacity ease-in;
                }
            }

            .toggle-words {
                &::before {
                    opacity: 0;
                    transition: $transition-length * 0.6 opacity ease-in;
                }

                &::after {
                    opacity: 1;
                    transition: $transition-length * 0.6 opacity ease-in $transition-length * 0.4;
                }
            }

            >.toggle-lines {
                >span {
                    &:first-child {
                        transform: rotate(45deg);
                        top: 4px;
                    }

                    &:nth-child(2) {
                        width: 0px;
                        margin-left: 10px;
                        opacity: 0;
                    }

                    &:last-child {
                        transform: rotate(-45deg);
                        top: -4px;
                    }
                }
            }
        }
    }
}
</style>
