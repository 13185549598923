// export const EventBus = new Vue();
import { TinyEmitter } from 'tiny-emitter';

 if (!global.EventBus) {
    global.EventBus = new TinyEmitter();
 }

export const EventBus = {
     $on: global.EventBus.on,
     $off: global.EventBus.off,
     $emit: global.EventBus.emit
 }
