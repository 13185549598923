import { createApp } from 'vue'

//Pinia and defined stores
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { useSfaStore } from "@/stores/sfa.js"
import { useUserStore } from "@/stores/user.js"
import { useClaimStore } from "@/stores/claim.js"
import { useAgencyCarrierStore } from "@/stores/agencycarrier.js"
import { useContentStore } from "@/stores/content.js"
import { useCourseStore } from "@/stores/course.js"
//End Pinia

import 'es6-promise/auto' 
import App from './App.vue'
import BaseMixIn from './base-mixin'
import router from '@/router'
import { store } from '@/store'
import axios from 'axios'
import './styles/common.scss'
import { dashboardApiEndpointConsts, baseConsts, queryStringConsts } from './constants';
import { OktaAuth
    } from '@okta/okta-auth-js';
    import { OIDC,
      routeNameConstants, 
     } from "@/constants"   
import OktaVue from '@okta/okta-vue' 
import actionHandler from "@/stores/actionHandler.js"
import PrimeVue from 'primevue/config';
import VueCookies from "@/cookieManager.js" //Custom plugin to replace original vuecookies

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

// Pinia and associated stores
app.use(pinia);
actionHandler.setupOnAction(useSfaStore());
actionHandler.setupOnAction(useUserStore());
actionHandler.setupOnAction(useAgencyCarrierStore());
actionHandler.setupOnAction(useClaimStore());
actionHandler.setupOnAction(useContentStore());
actionHandler.setupOnAction(useCourseStore());
const userStore = useUserStore();
//End Pinia

app.use(router);
app.use(store);
app.use(PrimeVue,{ unstyled: false });
app.mixin(BaseMixIn); //CTNOTE: Vue recommendation not to use mixin, possibly replace in the future.

//Other plugins
app.use(VueCookies)

//Okta Auth Config - this injects AuthState into components
const oktaAuth = new OktaAuth({
  issuer: OIDC.ISSUER,
  clientId: OIDC.CLIENT_ID,
  redirectUri: OIDC.REDIRECT_URI,
  restoreOriginalUri: async (oktaAuth) => {
      var isAuthenticated = oktaAuth.authStateManager._authState.isAuthenticated;
      var queryStringText = window.location.search.replace("?", "");
      var queryString, queryJson, urlBuilder, forwardedQuery, referral;

      //Handle the querystring for referral navigation. If referral is supplied,we need to grab
      //the route the referral points them to, send them to that route on login, and forward any
      //additional query string vars to that route.
      if (queryStringText?.length > 1) {
            urlBuilder = queryStringText.replace("?", "").split("&");

            //Grab referral QS var to determine route to forward to
            referral = urlBuilder.find((item) => item.includes(queryStringConsts.REFERRAL + "="));

            //If we have a referral (route to forward to), need to handle that qs var separately, 
            //otherwise we just have queries to forward.
            if(referral) {
                referral = referral.replace(queryStringConsts.REFERRAL + "=", "");

            
            //handle the remaining querystring, which will be forwarded if referral
            forwardedQuery = urlBuilder.filter((item) => !item.includes(queryStringConsts.REFERRAL + "="))

            //Build querystring object to forward.
            if(forwardedQuery.length > 0) {
                //Build JSON
                queryJson = "{"; 
                forwardedQuery.forEach((item, index) => {
                    let prop = item.replace("=", "\":\"")
                    queryJson = queryJson.concat("\"" + prop + "\"")

                    if(index === forwardedQuery.length - 1) {
                        queryJson = queryJson.concat("}")
                    }
                    else {
                        queryJson = queryJson.concat(",")
                    }
                })
                queryString = JSON.parse(queryJson);
            } else {
                queryString = null
            }
        }
            
      }

      if (isAuthenticated) {
          const idToken = oktaAuth.authStateManager._authState.idToken;

          if (idToken.claims.dashboardUserRole?.includes(OIDC.INTERNAL_ADMIN_ROLE)) {
              userStore.userRole = OIDC.INTERNAL_ADMIN_ROLE;
              
              if (referral == "start-claim" || referral == "dashboard" || referral == "agent-dashboard" || referral == routeNameConstants.FILE_CLAIM) {
                  router.replace({ name: routeNameConstants.INTERNAL_ADMIN_DASHBOARD });
              }
              else {
                  referral ? router.replace({ name: referral }) : router.replace({ name: routeNameConstants.INTERNAL_ADMIN_DASHBOARD });
              }
          } else if(idToken.claims.dashboardUserRole?.includes(OIDC.CE_ADMIN_ROLE)) {
              userStore.userRole = OIDC.CE_ADMIN_ROLE;

              referral ? router.replace({ name: referral, query: queryString }) : router.replace({ name: routeNameConstants.ADMIN_AGENT_DASHBOARD });

          } else {
              userStore.userRole = "User";
              if (referral == "internal-admin-dashboard" || referral == "dashboard" || referral == "agent-dashboard") {
                  router.replace({ name: routeNameConstants.ADMIN_AGENT_DASHBOARD });
              }
              else {
                  referral ? router.replace({ name: referral, query: queryString }) : router.replace({ name: routeNameConstants.ADMIN_AGENT_DASHBOARD });
              }
          }
      } else {
          userStore.userRole = "User";
          if (referral == "internal-admin-dashboard" || referral == "dashboard" || referral == "agent-dashboard")
              router.replace({ name: routeNameConstants.ADMIN_AGENT_DASHBOARD });
          else
              referral ? router.replace({ name: referral, query: queryString }) : router.replace({ name: routeNameConstants.ADMIN_AGENT_DASHBOARD });
      }

  },
  scopes: OIDC.SCOPES.split(",")
})

app.use(OktaVue, {
  oktaAuth
})
//END OktaAuth config

//CTNOTE: Remove app.use(BootstrapVue);
//app.use(VeeValidate);

//CTNOTE: Necessary?
app.config.errorHandler = function(err, vm, info) {
    if(baseConsts.DEBUG_ENABLED) {
      console.log(err);
      console.log(vm);
      console.log(info);
    }
    axios({
      method: "POST",
      url: dashboardApiEndpointConsts.VIEW_ERROR,
      data: {error: err.toString()},
      crossdomain: true,
      responseType: 'json'
  })
}

app.mount('#app');

