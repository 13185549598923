<template>
<modal-container :openModal="loading > 0" class="windshield-waiting-modal">
    <div class="windshield-waiting-box">
        <div class="windshieldImage" />
        <p class="just-a-sec">Just a sec...</p>
    </div>
</modal-container>
</template>

<script>
import {
    EventBus
} from "@/event-bus.js";
import {
    globalEventConsts
} from "@/constants.js";
import ModalContainer from "@/sharedcomponents/ModalContainer.vue";
export default {
    name: "WindshieldWaitingModal",
    data() {
        return {
            loading: 0,
            clearTabbing: null,
        }
    },
    components: {
        ModalContainer
    },
    mounted() {
        EventBus.$on(globalEventConsts.SHOW_WINDSHIELD_WAITING_MODAL, shouldShowWaitingModal => {
            if (shouldShowWaitingModal) {
                this.loading++;
            } else if (!shouldShowWaitingModal && this.loading > 0) {
                this.loading--;
            }
        });
    },
    updated() {
        if (document.getElementsByClassName('windshield-waiting-modal').length == 0 && this.loading > 0) {
            //if this component is updated, and it doesn't exist on the page.. put it back
            document.body.appendChild(this.$el); // places component at root of <body> tag for z-indexing purposes
        }
    },
}
</script>

<style lang="scss">
.windshield-waiting-modal {
    z-index: 105;
    text-align: center;
    overflow: hidden;

    .modal-box {
        background-color: transparent;
        box-shadow: none;
    }

    .windshield-waiting-box {
        position: relative;
        display: inline-block;
        padding: 30px;
        vertical-align: middle;
        background: $grayscale-eight;
        border-radius: 5px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

        strong {
            font-size: 20px;
        }

        subtext {
            font-size: 15px;
        }

        .windshieldImage {
            width: 160px;
            height: 120px;
            margin: auto;
            background-image: url("~@/assets/sprites/wipers-animation.jpg");
            -webkit-animation: play 1.5s steps(18) infinite;
            animation: play 1.5s steps(18) infinite;
        }

        @keyframes play {
            0% {
                background-position: 0px;
            }

            100% {
                background-position: -2880px;
            }
        }

        @-webkit-keyframes play {
            0% {
                background-position: 0px;
            }

            100% {
                background-position: -2880px;
            }
        }

        .just-a-sec {
            font-size: 20px;
            line-height: 1.5;
            color: $black;
        }

        .loading-our-secure-p {
            line-height: 1.56;
            width: 100%;
        }
    }

    &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
    }
}
</style>
