<template>
    <BasicModal :openModal="isModalOpen">
      <iframe
        width="560"
        height="315"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
        frameborder="0"
        :src="videoUrl"
      ></iframe>
    </BasicModal>
</template>

<script>
import BasicModal from "@/sharedcomponents/BasicModal.vue";
import { EventBus } from "@/event-bus.js";
import { globalEventConsts } from "@/constants.js";
export default {
    name: "YouTubeModal",
    components: {
        BasicModal
    },
    data() {
        return {
            isModalOpen: false,
            videoUrl: ""
        }
    },
    mounted() {
        EventBus.$on(globalEventConsts.SHOW_YOUTUBE_MODAL, (str) => {
            this.isModalOpen = true;
            this.videoUrl = str;
        })

        EventBus.$on(globalEventConsts.CLOSE_MODAL, () => {
            this.isModalOpen = false;
        })
    }
}
</script>