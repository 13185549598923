<template>
    <span :id="id"></span>
</template>

<script>
import { globalValueConsts } from "@/constants.js";
export default {
    name: "MoveToTopSpot",
    data() {
        return {
            id: globalValueConsts.MOVE_TO_TOP_LOCATION
        } 
    }
}
</script>