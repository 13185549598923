import { baseConsts, navigationScenarioConstants, commonMiscConstants, cookieKeys,pageConfigLayoutStyle  } from "./constants"
import globalMethods from "@/global-methods.js"
import { useSfaStore } from "@/stores/sfa.js"
import { v4 as uuidv4 } from 'uuid';

export default {
    methods: {
        pushGoogleDataLayer(toBePushed) {
            if (typeof dataLayer != 'undefined' &&
                dataLayer != null) {
                dataLayer.push(toBePushed);             
                if(toBePushed.category != undefined  && toBePushed.category != 'agent_direct')
                this.addLogAppPageView(toBePushed);
            }
        },
        addLogAppPageView(toBePushed) {
            var self = this;
            var cookieLogApp = self.$cookies.get("LogAppDetails");
            var store = useSfaStore();

            store.execLogPageViewNewAsync({
                 WebPage: toBePushed.path,
                 LogAppDetails: JSON.stringify(cookieLogApp),
                 Event:toBePushed.event != undefined ? toBePushed.event :'EVENT',
                 Category: toBePushed.category,
                 Action:toBePushed.action,
                 Label:toBePushed.label,
                 Value: 0,
                 ReferralNumber: toBePushed.referralNumber,
                 UserName: toBePushed.username
            }).then(response => {
               
            })
        },
        pushEventToGoogleDataLayer(category, action, label, value, path, referralNumber) {

            path = path != undefined ? path :location.pathname;
            this.pushGoogleDataLayer({
                'event': 'event',
                'category': category,
                'action': action,
                'label': label,
                'value': value,
                'path': path,
                'referralNumber': referralNumber
            });
        },
        dispatchBlockingStoreAction(type, payload) {
            globalMethods.showWaitingModal(true);

            return this.dispatchNonBlockingStoreAction(type, payload)
                .finally(() => {
                    globalMethods.showWaitingModal(false);
                });;
        },
        dispatchNonBlockingStoreAction(type, payload) {
            return this.$store.dispatch(type, payload);
        },
        //prevents focus on any element inside the passed element
        excludeTabbing(element) {
            var capturedTabbables = [];

            let capturables = Array.from(element.querySelectorAll(commonMiscConstants.FOCUS_SELECTOR));
            capturables.forEach(function (capturable) {
                if (document.activeElement === capturable) {
                    capturable.blur();
                }
                const setTabIndex = capturable.getAttribute("tabindex");
                if (setTabIndex) {
                    capturable.setAttribute("data-tabindex", setTabIndex);
                }
                capturable.setAttribute("tabindex", "-1");
                capturedTabbables.push(capturable);

            });

            return function () {
                capturedTabbables.forEach(function (release) {
                    const setTabIndex = release.getAttribute("data-tabindex");
                    if (setTabIndex) {
                        release.setAttribute("tabindex", setTabIndex);
                        release.removeAttribute("data-tabindex");
                    } else {
                        release.removeAttribute("tabindex");
                    }
                });
                capturedTabbables = null;
            };
        },
        getModelFromEndpoint(type, payload) {
            return this.dispatchNonBlockingStoreAction(type, payload);
        },
        runExperimentsForTrigger(triggerEvent, triggerValue) {
            let userId;

            const idToken = this.authState.idToken;
            const isUserLoggedIn = idToken != null;
            const userIdFromCookie = this.$cookies.get(cookieKeys.LOGGED_OUT_USER_ID);

            if (isUserLoggedIn) {
                userId = idToken.claims.sub;
            }
            else if (userIdFromCookie) {
                userId = userIdFromCookie;
            }
            else {
                userId = uuidv4();
                this.$cookies.set(cookieKeys.LOGGED_OUT_USER_ID, userId, -1);
            }

            let currentExperiments = this.experiments?.length > 0 && this.experiments[0].userId == userId ? this.experiments : [];

            //Loads store experiments prop for execution
            this.sfaStore.loadExperimentsForTriggerAsync(
                {
                    userId: userId,
                    triggerEvent: triggerEvent,
                    triggerValue: triggerValue,
                    currentExperiments: currentExperiments
                });
        },
    },
    computed: {
       pageConfigLayoutStyle(){
            return pageConfigLayoutStyle;
        },
        navigationScenarioConstants() {
            return navigationScenarioConstants;
        },
        experiments() {
            return this.sfaStore.experiments;
        },
        contentSiteRoutes() {
            return this.$router.getRoutes().filter(route => !route.path.includes(baseConsts.DASHBOARD_BASE));
        }
    },
}