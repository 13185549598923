<template>
<div v-clickOutsideExploreTopics="handleClickOutsideExploreTopics" class="site-nav-container">
    <nav class="main-nav general-nav">
        <ul class="level-0" ref="headerMainNav">
            <standard-header-main-navigation-item @item-clicked="$emit('item-clicked')" v-for="(link, i) in navigation" :key="i" :link="link"></standard-header-main-navigation-item>
            <StandardHeaderExploreTopicsMenu ref="exploreTopicsMenu" :initialIsOpen="isExploreTopicsMenuOpen" @explore-topics-menu-expanded="handleExploreTopicsMenuOpenChange" @item-clicked="$emit('item-clicked')" />
            <li class="file-claim-container" v-if="!shouldHideFileClaimButton">
                <button :class="isBtnDisabled? 'pill-btn blue filled full-width-mobile disabled': 'pill-btn red filled full-width-mobile' " ref="fileAClaim" :disabled='isBtnDisabled' id='fileClaimId' @click="navigateToFileClaim">File a claim now</button>
            </li>
        </ul>
    </nav>
</div>
</template>.
<script>
import StandardHeaderExploreTopicsMenu from "../StandardHeaderExploreTopicsMenu/StandardHeaderExploreTopicsMenu.vue";
import StandardHeaderMainNavigationItem from "../StandardHeaderMainNavigation/StandardHeaderMainNavigationItem.vue";
import {
    EventBus
} from "@/event-bus.js";
import { useUserStore } from "@/stores/user.js"
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useClaimStore } from "@/stores/claim.js"

export default {
    name: "standard-header-nav-container",
    setup() {
        const userStore = useUserStore();
        const claimStore = useClaimStore();
        return { userStore, claimStore };
    },
    components: {
        StandardHeaderMainNavigationItem,
        StandardHeaderExploreTopicsMenu
    },
    props: {
        navigation: Array,
        shouldHideFileClaimButton: Boolean,
    },

    emits: ['explore-topics-menu-expanded', 'item-clicked'],
    data() {
        return {
            authenticated: false,
            claims: "",
            isExploreTopicsMenuOpen: false,
            isBtnDisabled: false
        }
    },
    created() {
        this.isAuthenticated();
        this.$auth.authStateManager.subscribe(this.isAuthenticated);
    },
    mounted() {
        if (this.userStore.userAccountsCount != null) {
            this.isBtnDisabled = this.userStore.userAccountsCount == 0;
        }

        const { userAccountsCount } = storeToRefs(this.userStore);
        var self = this;
        watch(userAccountsCount, function(newValue, oldValue) {
            self.isBtnDisabled = newValue == 0;
        });
    },
    watch: {
        $route: "isAuthenticated",
        async authenticated() {
            this.claims = this.authenticated ? await this.$auth.getUser() : '';
            window.dataLayer.push({
                "customDimensionId": "ga:dimension1",
                "customDimensionDescription": this.claims.sub,  
                "Scope": "Hit",
                "Tag": this.claims.sub
            });
        },
    },
    methods: {
        navigateToFileClaim() {
            var self = this;
            this.claimStore.clearInsuranceClaimDetails({});
            this.claimStore.clearAdditionalDetails({});
            this.claimStore.clearCashQuoteInformation({});
            document.documentElement.classList.remove("nav-open");
            self.$router.navigate(self.navigationScenarioConstants.NAVIGATE_TO_FILE_CLAIM, {});
            this.pushEventToGoogleDataLayer("agent_portal", "file_claim_clicked_header", this.authenticated ? "logged_in_yes" : "logged_in_no", 0);
        },
        async isAuthenticated() {
            this.authenticated = await this.$auth.isAuthenticated();
        },
        handleClickOutsideExploreTopics() {
            this.isExploreTopicsMenuOpen = false;
        },
        handleExploreTopicsMenuOpenChange(isExploreTopicsMenuOpen) {
            this.isExploreTopicsMenuOpen = isExploreTopicsMenuOpen;
            this.$emit('explore-topics-menu-expanded', isExploreTopicsMenuOpen);
        }
    },
    directives: {
        clickOutsideExploreTopics: {
            bind: function (el, binding, vnode) {
                el.eventSetDrag = function () {
                    el.setAttribute('data-dragging', 'yes');
                }
                el.eventClearDrag = function () {
                    el.removeAttribute('data-dragging');
                }
                el.eventOnClick = function (event) {
                    var dragging = el.getAttribute('data-dragging');
                    const exploreTopicsMenu = vnode.context.$refs.exploreTopicsMenu.$el;
                    // Check that the click was outside the el and its children, and wasn't a drag
                    if (!(exploreTopicsMenu == event.target || exploreTopicsMenu.contains(event.target)) && !dragging) {
                        // call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.addEventListener('touchstart', el.eventClearDrag);
                document.addEventListener('touchmove', el.eventSetDrag);
                document.addEventListener('click', el.eventOnClick);
                document.addEventListener('touchend', el.eventOnClick);
            },
            unbind: function (el) {
                document.removeEventListener('touchstart', el.eventClearDrag);
                document.removeEventListener('touchmove', el.eventSetDrag);
                document.removeEventListener('click', el.eventOnClick);
                document.removeEventListener('touchend', el.eventOnClick);
                el.removeAttribute('data-dragging');
            },
        }
    }
};
</script>

<style lang="scss" scoped>
.site-nav-container {
    @import "@/styles/navigation/navigation-mixins.scss";
    @import "@/styles/navigation/chevron.scss";
    @import "@/styles/navigation/chevron-mixins.scss";
}

@include wide-mobile {
    .file-claim-container {
        padding: $spacer-five $spacer-four;
        border-bottom: 1px solid $grayscale-five;
    }

    .nav-open {
        .site-nav-container {
            z-index: 101;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.6);
            transition: $transition-length * 1.2 background-color ease-in;

            .level-0 {
                top: 0;
                opacity: 1;
            }
        }
    }

    .site-nav-container {
        transform: translateZ(0px);
        top: 56px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: $transition-length * 1.2 background-color ease-in,
            0s height $transition-length * 1.2;

        .level-0 {
            position: absolute;
            top: -20px;
            opacity: 0;
            transition: 0.1s opacity ease-out 0.17s,
                0.2s top linear 0.0s;
            width: 100%;
        }

        >div {
            height: 100vh;
            overflow: auto;
            top: -100vh;
            left: 0;
            right: 0;
            transition: $transition-length top ease-in;
        }
    }
}

@include tablet {
    .file-claim-container {
        padding-bottom: $spacer-three;

        .pill-btn {
            width: 160px;
            margin-left: $spacer-five;
        }
    }

    .main-nav {
        .level-0 {
            display: flex;
            align-items: center;
        }
    }
}
</style>
