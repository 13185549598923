<template>
<div v-if="isChatEnabled" ref="chatButton" class="header-chat-now-button" >
    <a @click="chatEmit()" class="chat-now d-none d-md-inline"> CHAT NOW </a>

    <div class="blue-circle center">
        <figure class="chat-icon-container">
            <img class="chat-icon" @click="chatEmit()" src="@/assets/icons/icon-chat.png" />
        </figure>
    </div>
</div>
</template>

<script>
import { dashboardRoutes } from "@/router/routes.js";
export default {
    name: "standard-header-chat-button",
    props: {
        isChatEnabled: Boolean,
        isSafeliteAgent: Boolean
    },
    emits : ['chat-button-clicked'],
    methods: {
        chatEmit(){
            this.$emit('chat-button-clicked');
            let eventCategory;
            if (dashboardRoutes.filter(route => route.path === this.$route?.path).length > 0) {
                eventCategory = this.isSafeliteAgent ? "agent_dashboard" : "agent_admin_dashboard";
            }
            else {
                eventCategory = "agent_care_desk";
            }

            this.pushEventToGoogleDataLayer(eventCategory, "chat_clicked", "icon_chat_button", 0);
        }
    }
};
</script>

<style lang="scss">
.header-chat-now-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        color: $blue;
        font-size: 14px;
        font-weight: $font-weight-bold;
    }

    .blue-circle {
        background-color: $blue;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: $spacer-three;

        .chat-icon-container {
            display: inline-flex;

            .chat-icon {
                width: 17px;
                height: 16px;
                margin-left: 1px;
            }
        }
    }

    .embeddedServiceHelpButton {
        visibility: visible;
        position: absolute;
        height: 30px;
        width: 100%;

        .helpButton {
            position: static;
            max-width: 100%;
            height: 100%;

            .uiButton {
                background: transparent;
                box-shadow: unset;
                border-radius: 0;
                min-width: unset;
                width: 100%;
                height: 100%;

                .helpButtonLabel,
                .embeddedServiceIcon::before {
                    display: none;
                }

                &.helpButtonDisabled {
                    display: none;
                }
            }
        }
    }

    .chat-now {
        font-size: 14px;
        color: $blue !important;
        font-weight: $font-weight-bold;
        line-height: 20px;
        letter-spacing: 0.75px;

        .chat-icon-container {
            display: inline-flex;
            background-color: $blue;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            justify-content: center;
            align-items: center;
            margin-left: $spacer-three;

            .chat-icon {
                width: 16px;
                height: 16px;
                margin-left: 1px;
            }
        }
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.embeddedServiceHelpButton .helpButton {
    visibility: hidden;
}
</style>
