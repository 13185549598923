<template>
  <div>
    <SiteFooterNavigation v-if="shouldShowSiteFooter" :tags="tags" />
  </div>
</template>

<script>
import SiteFooterNavigation from "./SiteFooterNavigation/SiteFooterNavigation.vue";
import SiteSubFooterNavigation from "./SiteSubFooterNavigation/SiteSubFooterNavigation.vue";
import { contentSiteRoutes } from "@/router/routes.js";
import { routeNameConstants } from "@/constants.js";

export default {
  name: "content-site-footer",
  components: {
    SiteFooterNavigation,
    SiteSubFooterNavigation,
  },
  props: {
    tags: Object
  },
  computed: {
    shouldShowSiteFooter() {
      const noFooterPages = contentSiteRoutes.map(x => x.name)
        .filter(x => 
          x == routeNameConstants.CONTENT_SITE_COURSE_REGISTRATION
        );
    
      return noFooterPages.indexOf(this.$route.name) == -1;
    },
  },
};
</script>