<template>
  <div class="site-sub-footer-navigation">
    <nav class="general-nav sub-footer-nav">
      <ul>
        <li>&copy; {{ currentYear }} Safelite Group</li>

        <li v-for="(link, i) in subFooterNavLinks" :key="i">
          <a :href="link.href" :target="link.target">{{ link.text }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { contentSiteConsts } from "@/constants.js"

export default {
  name: "site-sub-footer-navigation",
  data: function () {
    return {
      currentYear: new Date().getFullYear(),
      subFooterNavLinks: [
        {
          text: "Terms of service",
          href: `${contentSiteConsts.TERMS_OF_SERVICE}`,
          target: "_blank",
        },
        {
          text: "Privacy policy",
          href: `${contentSiteConsts.PRIVACY_POLICY}`,
          target: "_blank",
        },
        {
          text: "Cancellation policy",
          href: `${contentSiteConsts.CANCELLATION_POLICY}`,
          target: "_blank",
        },
        {
          text: "Do not sell my information",
          href: `${contentSiteConsts.DO_NOT_SELL_MY_INFORMATION}`,
          target: "_blank",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.site-sub-footer-navigation {
  padding: 30px $page-padding;
  text-align: center;

  .sub-footer-nav {
    max-width: 1020px;
    margin: 0 auto;

    ul {
      list-style: none;
      
      li, a {
        @include disclaimer-text;
      }

      li {
        display: inline-block;
        padding: 0 $spacer-three;
      }

      a {
        margin: 0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include wide-mobile {
  .site-sub-footer-navigation {
    border-top: $divider-primary;
  }

  .site-sub-footer-navigation .sub-footer-nav ul li {
    flex-grow: 1;
    margin-bottom: 15px;
    width: 100%;
  }
}

@include tablet {
  .site-sub-footer-navigation {
    border-top: 1px solid #d4d6d8;
  }
  .site-sub-footer-navigation .sub-footer-nav ul li {
    text-align: left;
    width: 50%;
    margin-bottom: $spacer-four;
  }
}

@include desktop {
  .site-sub-footer-navigation .sub-footer-nav ul li {
    width: auto;
    margin-bottom: 0;

    &:first-of-type {
      margin-left: 15px;
    }
  }
}
</style>