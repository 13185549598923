//////////////////////////////////////////////////////////////////////
// Methods to help router functionality, like loading of components //
//////////////////////////////////////////////////////////////////////

export function lazyLoadDashboardLayout(layoutName) {
    return () => import(`@/layouts/Dashboard/${layoutName}.vue`)
}

export function lazyLoadContentHubLayout(layoutName) {
    return () => import(`@/layouts/ContentSite/${layoutName}.vue`)
}