import { salesforceChatEnvironmentVariables } from '@/constants'

var initESW = function (gslbBaseURL, userinfo) {

    embedded_svc.settings.displayHelpButton = true; //Or false

    embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    // embedded_svc.settings.prepopulatedPrechatFields = {userinfo}; //Sets the auto-population of pre-chat form fields
    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
    if (userinfo) {
        embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName: userinfo.AgentFirstName,
            LastName: userinfo.AgentLastName,
            Email__c: userinfo.email,
            Account_Number__c: userinfo.AgentNumber.toString()

        }
    }
    embedded_svc.init(
        salesforceChatEnvironmentVariables.SALESFORCE_URL,
        salesforceChatEnvironmentVariables.SALESFORCE_CHAT_ENDPOINT,
        gslbBaseURL,
        salesforceChatEnvironmentVariables.SALESFORCE_CHAT_ID,
        'Agency_Care_Desk_Snap_in',
        {
            baseLiveAgentContentURL: salesforceChatEnvironmentVariables.SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL,
            deploymentId: salesforceChatEnvironmentVariables.SALESFORCE_DEPLOY_ID,
            buttonId: salesforceChatEnvironmentVariables.SALESFORCE_BUTTON_ID,
            baseLiveAgentURL: salesforceChatEnvironmentVariables.SALESFORCE_BASE_LIVE_AGENT_URL,
            eswLiveAgentDevName: salesforceChatEnvironmentVariables.SALESFORCE_ESW_LIVE_AGENT_NAME,
            isOfflineSupportEnabled: false
        }
        );
}

export default {
    importSalesforceScript() {
        let scriptLoadPromise = new Promise((resolve, reject) => {
            let script = document.createElement("script");
            script.src = "https://service.force.com/embeddedservice/5.0/esw.min.js";
            document.head.appendChild(script);
            script.addEventListener("load", () => {
                resolve(window.embedded_svc);
            });
        })

        return scriptLoadPromise;
    },
    openWebChat(userinfo) {
        if (!window.embedded_svc) {
            var s = document.createElement("script");
            s.setAttribute("src", salesforceChatEnvironmentVariables.SALESFORCE_EMBEDDED_SOURCE);
            s.onload = function () {
                initESW(null, userinfo);
            };
            document.body.appendChild(s);
        } else {
            initESW("https://service.force.com", userinfo);
        }
    },
    updateUserInfo (userInfo) {
        if(userInfo && window.embedded_svc) {
            window.embedded_svc.settings.prepopulatedPrechatFields = {
                FirstName: userInfo.AgentFirstName,
                LastName: userInfo.AgentLastName,
                Email__c: userInfo.email,
                Account_Number__c: userInfo.AgentNumber
            }
        }
    }
};