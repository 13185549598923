<template>
<ModalContainer :enableCloseButton="true" :openModal="openModal" @closing="closeModal">
    <div class="content-container">
        <slot></slot>

        <div class="button-container" :class="buttonContainerClasses">
            <button class="pill-btn primary-button" :class="[ primaryButtonClasses, primaryButtonColor === 'blue' ? 'blue filled' : 'red filled']" @click="primaryButtonClicked" v-if="primaryButtonText">{{primaryButtonText}}</button>
            <button class="secondary-button" :class="[ secondaryButtonClasses, isSecondaryButtonLink ? 'link' : 'pill-btn blue']" @click.prevent="secondaryButtonClicked" @keyup.enter="secondaryButtonClicked" v-if="secondaryButtonText">{{secondaryButtonText}}</button>
        </div>
    </div>
</ModalContainer>
</template>

<script>
import ModalContainer from "@/sharedcomponents/ModalContainer.vue";
export default {
    name: "ModalWithButtons",
    components: {
        ModalContainer,
    },
    props: {
        openModal: Boolean,
        primaryButtonText: String,
        secondaryButtonText: String,
        type: {
            type: String,
            default () {
                return "stacked";
            }
        },
        primaryButtonColor: String,
        isSecondaryButtonLink: Boolean
    },
    computed: {
        buttonContainerClasses() {
            switch (this.type)
            {
                case "box-footer": 
                    return "d-flex flex-md-row-reverse flex-column justify-content-between align-items-center"
                case "stacked":
                    return "d-flex flex-column";
                default:
                    break;
            }
        },
        primaryButtonClasses() {
            switch (this.type)
            {
                case "box-footer": 
                    return "pill-btn-small full-width-mobile"
                case "stacked":
                    return "full-width mb-5";
                default:
                    break;
            }
        },
        secondaryButtonClasses() {
            switch (this.type)
            {
                case "box-footer": 
                    return "mt-5-mobile";
                case "stacked":
                    return "full-width";
                default:
                    break;
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit("closing");
        },
        primaryButtonClicked() {
            this.$emit("primary-button-clicked");
        },
        secondaryButtonClicked() {
            this.$emit("secondary-button-clicked");
        },
    },
}
</script>

<style lang="scss" scoped>
.content-container {
    @include tablet {
        padding: $spacer-six;
    }

    @include wide-mobile {
        padding: $spacer-six $spacer-four;

        .mt-5-mobile {
            margin-top: $spacer-five;
        }
    }
}
</style>
