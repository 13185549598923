<template>
<nav class="footer-nav general-nav">
    <ul class="level-0">
        <li class="nav-title" v-if="footerNavSection">
            <span @click="toggleDropdown()" class="chevron collapsible" :class="{ open: this.isOpen }">
                {{ footerNavSection.title }}
            </span>
            <div>
                <ul class="level-1" v-if="footerNavSection.navigation">
                    <li v-for="(link, j) in footerNavSection.navigation" :key="j">
                        <router-link v-if="link.toTaggedArticleList" :to="{ name: 'Tagged Articles', query: { tag: link.tagKey } }" class="link">
                            {{ link.tagValue }}
                        </router-link>
                        <button v-else-if="link.navigationScenario" @click="navigateToScenario(link.navigationScenario)">
                            {{ link.text }}
                        </button>
                        <a v-else :href="link.destination" :target="link.target == undefined ? _self : link.target">{{ link.text }}</a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</nav>
</template>

<script>
import {
    navigationScenarioConstants,
    OIDC
} from "@/constants.js";
export default {
    name: "site-footer-navigation-section",
    props: {
        footerNavSection: Object,
    },
    data: function () {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        navigateToScenario(scenario) {
            this.$router.navigate(scenario);
        }
    },
};
</script>

<style lang="scss" scoped>
.footer-nav {
    @import "@/styles/navigation/navigation-mixins.scss";
    @import "@/styles/navigation/chevron.scss";
    @extend %general-nav;

    a,
    button {
        @include p1-text;
        text-decoration: none;
        background-color: transparent;
    }

    .chevron {
        @include chevron(12px, 2px, $grayscale-two, $grayscale-eight, 15px);

        &.open {
            @include chevron-open(12px, 2px, $primary, $grayscale-eight, 15px);
        }
    }

    .collapsible {
        +div {
            overflow: hidden;
            display: flex;
        }
    }

    .level-0 {
        >li {
            >span {
                @extend %nav-section-header;
            }
        }
    }
}

@include wide-mobile {
    .footer-nav {
        .level-0 {
            >li {
                border-top: $divider-primary;

                >span {
                    padding: 20px 15px;
                    background: linear-gradient(to right,
                            $primary,
                            $primary 5px,
                            transparent 5px,
                            transparent);
                    background-size: 100% 0;
                    background-repeat: no-repeat;

                    &.open {
                        background-size: 100% 100%;
                    }

                    +div {
                        height: auto;
                        max-height: 0;
                        transition: max-height $transition-length cubic-bezier(0.67, 0.9, 0.76, 0.37) $transition-length;
                    }
                }

                .open+div {
                    overflow: auto;
                    height: auto;
                    max-height: 250px;

                    .level-1 {
                        >li {
                            left: 0;
                            opacity: 1;

                            @for $i from 1 through 10 {
                                &:nth-child(#{$i}) {
                                    transition: $transition-length opacity ease-out $i * 0.08s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include tablet {
    .footer-nav {
        padding-left: 20px;
        flex: 0 1 auto;

        .chevron {

            &::after,
            &::before {
                content: none;
            }

            +div {
                overflow: visible;
                height: auto;
                max-height: none;

                .level-1 {
                    margin-bottom: 0;
                    max-height: none;
                    padding-bottom: 10px;
                }
            }
        }

        .level-1 {
            li {
                padding: 8px 0 0;
                opacity: 1 !important;
            }

            a,
            button {
                border-bottom: 2px solid rgba($red, 0);
                padding-bottom: 4px;
                padding-top: 6px;
                display: inline-block;
                transition: $transition-length all linear $transition-length * 0.2;

                &:hover {
                    border-bottom-color: rgba($red, 1);
                    transition: $transition-length all linear;
                }
            }
        }
    }
}
</style>
