<template>
<div class="web-chat-button"></div>
</template>

<script>
import webChat from "@/chat.js";
import {
    EventBus
} from "@/event-bus.js";
import {
    globalEventConsts
} from "@/constants.js";
import { useUserStore } from "@/stores/user.js"
import { useSfaStore } from "@/stores/sfa.js"

export default {
    name: "WebChatButton",
    setup() {
        const userStore = useUserStore();
        const sfaStore = useSfaStore();
        return { userStore, sfaStore };
    },
    data() {
        return {
            hasAgentAvailableEventBeenLogged:false,
            hasAgentNotAvailableEventBeenLogged:false,
            userInformation: {
                AgentFirstName: '',
                AgentLastName: '',
                email: '',
                AgentNumber: ''
            }
        }
    },
    methods: {
        openWebChat() {

            if(this.authState?.idToken?.claims) {
                this.userInformation.AgentNumber = this.userStore.activeAgencyNumber.toString();
                webChat.updateUserInfo(this.userInformation);
            }
            
            const chatButton = document.getElementsByClassName("helpButtonEnabled")[0];
            chatButton.click();
        },
        importSalesforceScript() {
            webChat.importSalesforceScript().then((embedded_svc) => {
                setTimeout(() => {
                    this.sfaStore.webChatEnabled = embedded_svc.settings.agentAvailableOnButtonClick;
                }, 2000);
                embedded_svc.addEventHandler("onAvailability", (data) => {
                    const isAgentAvailable = data.isAgentAvailable
                    if (isAgentAvailable && !this.hasAgentAvailableEventBeenLogged) {
                        this.hasAgentAvailableEventBeenLogged = true; 
                        this.hasAgentNotAvailableEventBeenLogged = false;
                        this.pushEventToGoogleDataLayer("web_chat", "agent", "available", 0);
                    }
                    else if (!isAgentAvailable && !this.hasAgentNotAvailableEventBeenLogged) {
                        this.hasAgentNotAvailableEventBeenLogged = true;
                        this.hasAgentAvailableEventBeenLogged = false; 
                        this.pushEventToGoogleDataLayer("web_chat", "agent", "not_available", 0);
                    }
                     this.sfaStore.webChatEnabled = isAgentAvailable;
                });
                 embedded_svc.addEventHandler("onChatEstablished", (data) => {
                    this.pushEventToGoogleDataLayer("web_chat", "chat_start", data.liveAgentSessionKey ? "success" : "fail", 0); 
                });
                if (this.authState.idToken) {
                    webChat.openWebChat(this.userInformation);
                } else {
                    webChat.openWebChat(null);
                }
            });
        },
    },
    mounted() {
        EventBus.$on(globalEventConsts.OPEN_CHAT, this.openWebChat);
        this.importSalesforceScript();
        
    },
    watch: {
        'authState.idToken.claims'(userInfo) {
            if (userInfo) {
                    this.userInformation.AgentFirstName=userInfo.AgentFirstName
                    this.userInformation.AgentLastName=userInfo.AgentLastName
                    this.userInformation.email=userInfo.email
            }
        }
    }
};
</script>

<style lang="scss">
.embeddedServiceHelpButton {
    .helpButton {
        .uiButton {
            background-color: #167cac;
            font-family: "Arial", sans-serif;
            &:focus {
                outline: 1px solid #167cac;
            }
            &.helpButtonEnabled {
                position: absolute;
                top: -99999px;
                width: 0;
                height: 0;
            }
            &.helpButtonDisabled {
                display: none;
            }
        }
    }
}
.embeddedServiceSidebar {
    h2,
    p.announcement,
    p.text {
        color: $grayscale-eight;
    }
}
</style>