import { globalEventConsts } from './constants'
import { EventBus } from "@/event-bus.js";


//Any simple global methods which we would like to use site-wide should go here. NOTE: 
//since this is global, we do not want circular dependencies. Due to this, do not add any
//more imports to this file unless absolutely necessary.
export default {
    showWaitingModal(shouldShowWaitingModal) {
        EventBus.$emit(globalEventConsts.SHOW_WINDSHIELD_WAITING_MODAL, shouldShowWaitingModal);
    },
    getCookie(name) { //CTNOTE: I think we can remove this, I dont see it being used anywhere.
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },
    //format phone in xxx-xxx-xxxx
    formatPhoneNumber(phoneNumber) {
        return phoneNumber != null ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : "";
    },
    scrollToTop() {
        //make toast msg visible.
        window.scrollTo(0,0); 
    },

}