<template>
    <div class="modal-container" v-if="openModal" jest="modal-container">
        <div class="modal-overlay" @click="closeIfAllowed"></div>
        <div :class="modalName && isMobileCheck ? 'mobile-box' : 'modal-box'">
            <a tabindex="-1" class="focus-start"></a>
            <slot></slot>
            <a tabindex="0" class="modal-close" @click.prevent="close" @keyup.enter="close" v-if="enableCloseButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                    <path fill="#167CAC" fill-rule="nonzero" d="M15.687 1.936L9.51 8.5l6.178 6.564c.417.443.417 1.161 0 1.604a1.031 1.031 0 0 1-.754.332 1.03 1.03 0 0 1-.754-.332L8 10.104l-6.178 6.564a1.032 1.032 0 0 1-.755.332 1.03 1.03 0 0 1-.754-.332 1.184 1.184 0 0 1 0-1.603l5.72-6.078.458-.487L.313 1.936a1.184 1.184 0 0 1 0-1.604 1.023 1.023 0 0 1 1.509 0l5.72 6.077.458.487L14.18.332a1.023 1.023 0 0 1 1.508 0c.417.443.417 1.16 0 1.604z"/> 
                </svg>
            </a>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "@/event-bus.js";
    import { globalEventConsts } from "@/constants.js";
    export default {
        name: "modal-container",
        props: [
            "openModal",
            "enableBackgroundClosing",
            "enableCloseButton",
            "primaryButtonText",
            "secondaryButtonText",
            "modalName",
            "isMobileCheck"
        ],
        emits: ['closing','continue','primary-button-clicked','secondary-button-clicked'],
        data(){
            return { 
                clearTabbing: null, 
                primaryButtonDisabled: false
            }
        },
        methods: {
            close(){
                this.$emit("closing");
            },
            continueClick(){
                this.$emit("continue");
            },
            primaryButtonClicked() {
                this.$emit("primary-button-clicked");
            },
            secondaryButtonClicked() {
                this.$emit("secondary-button-clicked");
            },
            closeIfAllowed(){
                if (this.enableBackgroundClosing){
                    this.close();
                }
            }, 
            disableContinueButton(){
                this.primaryButtonDisabled = true;
            }, 
            enabledContinueButton(){
                this.primaryButtonDisabled = false;
            }
        },
        updated(){
            if (document.getElementsByClassName('modal-container').length == 0 && this.openModal){
                //if this component is updated, and it doesn't exist on the page.. put it back
                //During router navigation, we delete it from the DOM, but need to put it back when we need to use it again
                document.body.appendChild(this.$el); // places component at root of <body> tag for z-indexing purposes
            }
        }
    }
</script>

<style lang="scss">
.modal-container {
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    padding: $spacer-six $spacer-four;
    overflow:auto;
    z-index: 201;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQI12NgYGDYDAAAuAC0TCbBxgAAAABJRU5ErkJggg==");
    
    @media screen and (min-width: $break-point-md){
        padding-top: $spacer-eight;
    }
    .focus-start:focus{
        box-shadow: none;
    }
    .modal-overlay{
        position:fixed;
        top:0;
        right:20px;
        bottom:0;
        left:0;
    }
    .modal-box{
        position:relative;
        margin:0 auto;
        background:$grayscale-eight;
        border-radius: 5px;
        box-shadow: 0 0 12px rgba(0,0,0,0.2);
        max-width:600px;
    }
    .modal-close{
        position:absolute;
        top: $spacer-four;
        right: $spacer-four;
        height:17px;
        width:16px;
        svg {
            position: relative;
            top: -1px;
        }
        &:hover{
            text-shadow: none;
        }
    }

    @include wide-mobile  {
        .mobile-box {
            position: absolute;
            margin: auto 15px;
        }
    }
}
</style>