import globalMethods from '@/global-methods'

export default {
    setupOnAction(store) {
        store.$onAction(({name, store, args, after, onError }) => {
            const blocking = args && Array.isArray(args) && args.length === 1 && args[0]["blocking"];
            if (blocking) {
                globalMethods.showWaitingModal(true);
            }
            after(result => {
                if (blocking) {
                    globalMethods.showWaitingModal(false);
                }
            })
            onError(error => {
                if (blocking) {
                    globalMethods.showWaitingModal(false);
                }
            })
        });
    }
}
