<template>
<div class="search-input">
    <form class="search-form" :class="{ focused: inputIsFocused, error: hasError }">
        <input class="search-input-field" @focus="inputIsFocused = true" @blur="inputIsFocused = false" ref="searchInput" type="text" placeholder="Search" v-model="searchTerm" @keydown="resetError" :class="{ error: hasError }">

        <button class="search-button" @click.prevent="search" :class="[hasInput ? 'arrow' : 'magnifying-glass']"></button>
    </form>

    <div class="error-message" v-if="hasError">Please enter a valid search term</div>
</div>
</template>

<script>
export default {
    name: "SearchInput",
    props: {
        allowEmptyInput: Boolean,
    },
    emits: ['search'],
    data() {
        return {
            searchTerm: "",
            hasError: false,
            inputIsFocused: false,
        }
    },
    methods: {
        search() {
            this.errorValidation();
            if (this.hasError) {
                this.$refs.searchInput.focus();
            } else {
                this.$emit("search", this.searchTerm.trim());
                this.searchTerm ="";
            }
        },
        resetError() {
            this.hasError = false;
        },
        errorValidation() {
            this.hasError = !this.allowEmptyInput ? this.searchTerm.trim() == "" : false;
        },
    },
    computed: {
        hasInput() {
            return this.searchTerm.trim() !== "";
        }
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    width: 290px;

    &.full-width {
        width: 100%;
    }

    .search-form {
        width: 100%;
        height: 45px;
        position: relative;
        border-radius: 22.5px;
        display: flex;
        border-width: 1px;
        border-style: solid;
        border-color: $grayscale-five;
        @include box-shadow-mixin(0 1px 5px 0 rgba(0, 0, 0, 0.2));

        &.focused {
            @include box-shadow-mixin(0 0 0 5px $blue-thirty);
        }

        &.error {
            border: 1px solid $red;

            &.focused {
                @include box-shadow-mixin(0 0 0 5px $red-thirty);
            }
        }

        input {
            padding: $spacer-three $spacer-five;
            flex-grow: 1;
            height: 100%;
            border-radius: 22.5px 0 0 22.5px;
            box-shadow: none;
            border: 0;

            &:focus {
                @include box-shadow-mixin(none);
            }

            &::-ms-clear {
                display: none;
            }
        }

        button {
            width: 40px;
            height: 43px;
            border-radius: 0 25px 25px 0;
            outline: 0;
            position: relative;
            background-color: $grayscale-eight;

            &::before {
                content: "";
                border-style: solid;
                border-color: $blue;
                display: block;
                position: absolute;
                transform-origin: 50% 50%;
                transition: 0.2s all linear;
            }

            &::after {
                content: "";
                position: absolute;
                background: $blue;
                transition: 0.2s all linear;
                transform-origin: 0 0;
                height: 2px;
            }

            &:hover {
                cursor: pointer;
            }

            &.magnifying-glass {
                &::before {
                    border-radius: 9px;
                    transform: rotateZ(200deg);
                    height: 14px;
                    width: 14px;
                    top: 13px;
                    left: 8px;
                    border-width: 2px;
                }

                &::after {
                    transform: rotateZ(45deg);
                    top: 24px;
                    left: 20px;
                    width: 7px;
                }
            }

            &.arrow {
                &::before {
                    border-width: 2px 2px 0 0;
                    height: 12px;
                    width: 12px;
                    top: 16px;
                    left: 12px;
                    border-radius: 0;
                    transform: rotateZ(45deg);
                }

                &::after {
                    width: 14px;
                    top: 23px;
                    left: 24px;
                    transform: rotateZ(-180deg);
                }

                &:hover {
                    &::before {
                        left: 17px;
                    }

                    &::after {
                        left: 29px;
                    }
                }
            }
        }
    }

    @include wide-mobile {
        &.full-width-mobile {
            width: 100%;
        }
    }

    .error-message {
        margin-top: $spacer-two;
        color: $red;
    }
}

.focused-from-tabbing {
    .search-form {
        button {
            &:focus {
                @include box-shadow-mixin(0 0 0 5px $blue-thirty);
            }
        }

        &.error {
            button {
                &:focus {
                    @include box-shadow-mixin(0 0 0 5px $red-thirty);
                }
            }
        }
    }
}
</style>
