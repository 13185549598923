import { defineStore } from 'pinia'
import httpClient from '@/http-client.js';
import {
    dashboardApiEndpointConsts
} from "@/constants.js";
  
export const useCourseStore = defineStore('course', {
    state: () => ({
        sample: null,
    }),
    persist: true,
    getters: {
    },
    actions: {
        execAddParticipantLinkDetailsAsync(args){
          return httpClient.callHttpClient({
            method: 'POST',
            endpoint: dashboardApiEndpointConsts.ADD_PARTICIPANTLINK,
            payload:
          {
            "OktaID": args.participantLink.OktaID,
            "CourseID": args.participantLink.CourseID,
            "FirstName": args.participantLink.firstName,
            "LastName":args.participantLink.lastName,
            "StreetAddress": args.participantLink.streetAddress,
            "City": args.participantLink.city,
            "State": args.participantLink.state.text,
            "Zip": args.participantLink.zipCode,
            "JobTitle": args.participantLink.jobTitle,
            "AgencyName": args.participantLink.agencyName,
            "EmailAddress": args.participantLink.emailAddress,
            "AgencyNumber": args.participantLink.AgencyNumber,
            "NpnId": args.participantLink.npnId
          },
        }).then(function (response) {
            return response;
        });
        },
        execDeleteCECourseAsync(args) {
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.DELETE_CE_COURSE,
              payload: {
                "courseID": args.ceCourseID
              }
            }).then(function (response) {
                return response;
            });
        },
        getCECourseDetailByCourseIDAsync(args){
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.GET_CE_COURSE_DETAILS_BY_COURSEID,
              payload:{ "CourseID" : args.CourseID },
            })
              .then(function (response) {
                return response.data;
              });
          },
          getCECourseImagesAsync(args) {
            return httpClient.callHttpClient({
              method: 'GET',
              endpoint: dashboardApiEndpointConsts.GET_CE_COURSE_IMAGES,
              payload:{},
            })
              .then(function (response) {
                return response.data;
              });
          },
          getCECourseListAsync(args) {
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.GET_CE_COURSE_LIST,
              payload:{
                "DisableDateFilter": args.disableDateFilter,
                "OktaID": args.OktaID
              },
            })
              .then(function (response) {
                return response.data;
              });
          },
          getMyCECourseListAsync(args) {
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.GET_MY_CE_COURSE_LIST,
              payload:{
                "DisableDateFilter": args.disableDateFilter,
                "OktaID": args.OktaID
              },
            })
              .then(function (response) {
                return response.data;
              });
          },
          getValidateCourseIsMaximumLimitAsync(args){
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.GET_VALIDATE_COURSE_IS_MAXIMUM_LIMIT,
              payload:{ "CourseID" : args.CourseID },
            })
              .then(function (response) {
                return response.data;
              });
          },
          execSaveCourseAsync(args) {
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.SAVE_COURSE,
              payload: {
                "RequiredFormData": args.requiredFormData,
                "VenueData": args.venueData,
                "flag": args.flag,
                "EventDateStart": args.EventDateStart,
                "EventDateEnds" : args.EventDateEnds,
                "imageURI" : args.imageURI,
                "courseID" : args.courseID,
                "formatedMealTiming1": args.formatedMealTiming1,
                "formatedMealTiming2": args.formatedMealTiming2,
                "formatedMealTiming3": args.formatedMealTiming3,
                "mealName1": args.mealName1,
                "mealName2": args.mealName2,
                "mealName3": args.mealName3
              },
            })
              .then(function (response) {
                return response.data;
              });
          },
        }
    })
