<template>
  <div class="modal-wrapper" v-if="openModal">
    <div class="modal-box">
      <slot></slot>
    </div>

    <div v-if="!disableCloseButton" @click="closeModal()" class="modal-close">×</div>
  </div>
</template>

<script>
import { globalEventConsts } from "@/constants.js"
import { EventBus } from "@/event-bus.js"
export default {
  name: "BasicModal",
  methods: {
    closeModal() {
      EventBus.$emit(globalEventConsts.CLOSE_MODAL);
    },
  },
  props: {
    disableCloseButton: Boolean,
    openModal: Boolean
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
  text-align: center;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }

  .modal-box {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-align: left;

    & > * {
      max-width: 100%;
    }
  }

  .modal-close {
    position: absolute;
    z-index: 105;
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: #fff;
    line-height: 40px;
    text-align: center;
    top: 20px;
    right: 20px;
    border: 1px solid #000;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>