<template>
<div id="app" @mouseup="handleMouseup" @focus="handleCheckboxFocus()">
    <WebChatButton />
    <MoveToTopSpot />
    <StandardHeader />
    <GlobalBannerAlert />
    <YouTubeModal />
    <AlertBanner :isOpenProp="showAlertBanner" :type="alertBannerType" :autoHide="autoHide" @alert-banner-closed="showAlertBanner = false" :class="alertBannerType" :message="alertBannerMessage" />
    <router-view />
    <StandardFooter :tags="tags" :isContentHubPath="isContentHubPath" />
    <WindshieldWaitingModal />
</div>
</template>

<script>
import StandardHeader from "./sharedcomponents/StandardHeader/StandardHeader.vue";
import StandardFooter from "./sharedcomponents/StandardFooter/StandardFooter.vue";
import WindshieldWaitingModal from "./sharedcomponents/WindshieldWaitingModal.vue";
import WebChatButton from "./sharedcomponents/WebChatButton.vue";
import GlobalBannerAlert from "./sharedcomponents/GlobalBannerAlert.vue";
import MoveToTopSpot from "@/sharedcomponents/MoveToTopSpot.vue";
import AlertBanner from "@/sharedcomponents/AlertBanner.vue";
import YouTubeModal from "@/sharedcomponents/YouTubeModal.vue";

import {
    EventBus
} from "@/event-bus.js";
import {
    globalEventConsts,
    routeNameConstants,
    dashboardApiEndpointConsts
} from "@/constants";
import httpClient from "@/http-client.js"
import { useContentStore } from "@/stores/content.js"
import { useSfaStore } from "@/stores/sfa.js"	

export default {
    name: "app",
    setup() {
        const contentStore = useContentStore();
        const sfaStore = useSfaStore();
        return { contentStore, sfaStore };
    },
    data() {
        return {
            showAlertBanner: false,
            alertBannerMessage: "",
            alertBannerType: "",
            autoHide: false,
            isErrorLogPageView: false
        }
    },
    components: {
        StandardHeader,
        StandardFooter,
        WindshieldWaitingModal,
        WebChatButton,
        GlobalBannerAlert,
        MoveToTopSpot,
        AlertBanner,
        YouTubeModal
    },
    mounted() {
        EventBus.$on(globalEventConsts.SHOW_ALERT_BANNER, (type, message, dataLayer) => {
            this.showAlertBanner = true;
            this.alertBannerType = type;
            this.alertBannerMessage = message;
            this.autoHide = false;
            if (!this.isErrorLogPageView) {
                this.addToLogAppDetails(dataLayer);
            }
            if (type == 'success') {
                this.autoHide = true;
            }
        });
        this.contentStore.loadTagsAsync();
    },
    computed: {
        tags() {
            return this.contentStore.tags;
        },
        isContentHubPath() {
            return this.contentSiteRoutes.filter(route => route.name === this.$route.name).length > 0;
        }
    },
    methods: {
        handleMouseup(e) {
            if (e) {
                const eventTarget = e.target;
                if (eventTarget && (eventTarget.tagName == "A" || eventTarget.tagName == "BUTTON" || (eventTarget.tagName == "INPUT" && eventTarget.type === "checkbox") || eventTarget.classList.contains("link") || eventTarget.classList.contains("pill-btn"))) {
                    eventTarget.blur();
                } else if (eventTarget.tagName === "LABEL") {
                    const correspondingInput = document.getElementById(eventTarget.getAttribute("for"));
                    if (correspondingInput) {
                        correspondingInput.addEventListener("focus", this.handleCheckboxFocus);
                    }
                }
            }
        },
        handleCheckboxFocus(e) {
            if (e) {
                e.target.blur();
                e.target.removeEventListener(e.type, this.handleCheckboxFocus);
            }
        },
        setupAnalytics(to) {
            var self = this;
            var userAgent = navigator.userAgent;
            userAgent = userAgent.replace(new RegExp("\\bEdg\\b"), "Edge");
            var clientTag = this.$cookies.get('clientTag');
            var userName = this.authState.idToken ? this.authState.idToken.claims.email : 'Unknown';
            var deviceId = this.$cookies.get('DeviceID') != null ? this.$cookies.get('DeviceID') : '';
            self.sfaStore.execSetupAndGetAnalyticsDataAsync({
                        ClientTag: clientTag,
                        UserName: userName,
                        UserAgent: userAgent,
                        DeviceId: deviceId
                    })
                .then(function (response) {
                    self.$cookies.set("LogAppDetails", response, 0);
                    if (self.$cookies.get('DeviceID') == null) {
                        self.$cookies.set("DeviceID", response ?.DeviceId, '90d');
                    }
                    if (self.$cookies.get('UserID') == null) {
                        self.$cookies.set("UserID", response ?.UserId, '90d', null, "safeliteforagents.com"); ;
                    } 
                    //event entry as start 
                    var request = {
                        path: to.path,
                        event: 'ENTRY',
                        username: userName
                    };
                    self.addLogAppPageView(request);
                });

        },
        
        //Send a new pageview to logapp DB
        addToLogAppDetails(dataLayer) {
            //Logapp details are stored in a cookie (device info, etc)
            var cookieLogApp = this.$cookies.get("LogAppDetails");
            if (Array.isArray(dataLayer)) {
                var _dataLayer = dataLayer[dataLayer.length - 1];
                this.sfaStore.execLogPageViewNewAsync(
                    {
                        WebPage: location.pathname, 
                        LogAppDetails: JSON.stringify(cookieLogApp), 
                        Event: _dataLayer.event ? _dataLayer.event : 'EVENT',
                        Category: _dataLayer.category,
                        Action: _dataLayer.action,
                        Label: _dataLayer.label,
                        Value: _dataLayer.value,
                        ReferralNumber: _dataLayer.referralNumber
                    }).then(function (response) {
                        return response.data;
                    }).catch((err) => {
                        if (err) {
                        this.isErrorLogPageView = true;
                        }
                    });
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
    },
    watch: {
        //CTNOTE: I think we can do this better..
        $route(to, from) {
            const namesOfRoutesWithRedirects = [
                routeNameConstants.FILE_CLAIM,
                routeNameConstants.CREATE_CE_COURSES,
            ]

            if (!namesOfRoutesWithRedirects.includes(to.name)) {
                this.pushEventToGoogleDataLayer("agent_direct", "agent_logged_in", this.authState.idToken ? "yes" : "no", 0, to.path);
            }
            if (this.$cookies.get('LogAppDetails') == null) {
                this.setupAnalytics(to);
            } else {

                var userName = this.authState.idToken ? this.authState.idToken.claims.email : 'Unknown';
                if (to.path) {
                    var request = {
                        path: to.path,
                        event: 'ENTRY',
                        username: userName 
                    };
                    this.addLogAppPageView(request);
                }
            }

        },
    }
};
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
