import 'es6-promise/auto'
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import httpClient from '../http-client.js';



  //  ---------- Global state ------------
const getDefaultState = () => {
    return {
         //Empty, yay!
  }
}
    
export const state = getDefaultState();

  //  ---------- Setters ------------
 export const mutations = {
    //Empty, yay!
  }

   
  //  ---------- Getters ------------
  export const getters = {
    //Empty, yay!
  }


  //  ---------- Updates ------------
  export const actions = {
 //Empty, yay!

  }



const store = createStore({
  plugins: [
      createPersistedState()
  ],
  state,
  mutations,
  getters,
  actions,
});

export { store }

//  ---------- Private Methods ------------
function callHttpClient({
  method,
  endpoint,
  payload,
  responseType = 'json',
  ignoreErrors = false
}) {
  return httpClient.callHttpClient({
    method: method,
    endpoint: endpoint,
    payload: payload,
    responseType: responseType,
    ignoreErrors: ignoreErrors
  });
}

//In very rare scenarios, we need to hit an endpoint before the user's
//tokens have been put in localstorage. This allows us to make those calls.
function callHttpClientPassToken({
  method,
  endpoint,
  payload,
  responseType = 'json',
  ignoreErrors = false,
  bearerToken = null
}) {
  return httpClient.callHttpClient({
    method: method,
    endpoint: endpoint,
    payload: payload,
    responseType: responseType,
    ignoreErrors: ignoreErrors,
    bearerToken: bearerToken
  });
}