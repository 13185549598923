<template>
<transition name="fade">
    <div class="alert-banner" :class="type" v-if="isOpenProp && isOpenData">
        <div class="alert-banner-container" :style="hasSpace ? 'margin-bottom:8px;':''">
            <div class="message-container d-flex">
                <span class="alert-banner-message" v-html="message" @click="handleClicks"></span>
                <img tabindex="0" @keydown.enter="closeAlertBanner()" @click="closeAlertBanner()" class="alert-banner-close-button" src="~@/assets/icons/icons-close-circle-secondary@3x.png" alt="">
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import { globalValueConsts } from "@/constants.js";
import globalMethods from "@/global-methods.js"
export default {
    name: "AlertBanner",
    props: {
        type: String,
        message: String,
        isOpenProp: {
            type: Boolean,
            default () {
                return true;
            }
        },
        autoHide: Boolean,
         hasSpaceProp: {
            type: Boolean,
            default () {
                return true;
            }
        },
    },
    emits: ['alert-banner-closed'],
    data() {
        return {
            isOpenData: true,
            hasSpace: true,
        }
    },
    methods: {
        closeAlertBanner() {
            this.isOpenData = false;
            this.hasSpace = false;
        },
        hideBannerAfterSetTime() {
            if (this.autoHide && this.type != globalValueConsts.ALERT_BANNER_RED && this.type != globalValueConsts.ALERT_BANNER_GREEN_PERSIST) {
                
                //Any time the toast message shows, scroll to the top of the page so that the user sees the message.
                globalMethods.scrollToTop();
                setTimeout(() => {
                    this.closeAlertBanner();

                }, 3000);
            }
        },
        navigateToRoute(page) {
           if(page=="Agency Care Desk")
           {
            this.$router.navigate(this.navigationScenarioConstants.NAVIGATE_TO_AGENCY_CARE_DESK, {});
           }
           else if(page=="Manage my account")
           {
            this.$router.navigate(this.navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS,{userId: this.authState.idToken.claims.sub});   
           }
        },
        handleClicks(event) 
        {
            if(event.target.tagName === 'A'){
              this.navigateToRoute(event.target.name);
            }
        },
    },
    watch: {
        isOpenProp(newOpenProp) {
            this.isOpenData = true;

            if (newOpenProp) {
                this.hideBannerAfterSetTime();
            }
        },
        isOpenData(isOpenData) {
            if (!isOpenData) {
                this.$emit("alert-banner-closed");
            }
        },
        autoHide() {
            this.hideBannerAfterSetTime();
        }, 
        type() {
            this.hideBannerAfterSetTime();
        }
    },
    mounted() {
        this.hasSpace = this.hasSpaceProp;
    }
}
</script>

<style lang="scss" scoped>
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.alert-banner {
    width: 100%;
    @include p2-text;
    font-weight: $font-weight-bold;
    color: $grayscale-eight;

    &.success {
        background-color: $green;
        z-index: 100;
    }

    &.success-persist {
        background-color: $green;
        z-index: 100;
    }

    &.warning {
        background-color: $red;
    }

    .message-container {
        max-width: $page-width-padded;
        padding: $spacer-three $page-padding;
        margin: auto;
        align-items: center;

        .alert-banner-message{
            text-align: center;
            flex-grow: 1;
        }

        .alert-banner-close-button {
            width: 20px;
            height: 20px;
            outline: none;

            &:hover {
                cursor: pointer;
            }

            &:focus {
                @include box-shadow-mixin(0 0 0 5px $blue-thirty);
            }
        }
    }

    @include tablet {
        .message-container {
            align-items: center;
        }
    }

    @include wide-mobile {
        .message-container {
            align-items: start;

            img {
                margin-top: 2.5px;
                margin-left: $spacer-three;
            }
        }
    }
}
</style>
