<template>
	<ul class="topics-menu level-0" :class="{ 'pr-0': isUserInternalAdmin }">
		<li ref="dropdown" class="item" @click.stop="toggleDropdown()">
			<span class="chevron collapsible" :class="{ open: isOpen }">
				Explore
			</span>
			<div :style="style">
				<ul class="level-1">
					<li
						class="item"
						@click="$emit('item-clicked')"
						v-for="(tagValue, tagKey) in tags"
						:key="tagKey"
					>
						<router-link
							:to="{ name: 'Tagged Articles', query: { tag: tagKey } }"
							target="_self"
						>
							{{ tagValue }}
						</router-link>
					</li>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
import { OIDC } from "@/constants.js";
import { useContentStore } from "@/stores/content.js";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useUserStore } from "@/stores/user.js";

export default {
	name: "StandardHeaderExploreTopicsMenu",
	setup() {
		const contentStore = useContentStore();
		const userStore = useUserStore();
		return { contentStore, userStore };
	},
	props: {
		initialIsOpen: Boolean,
	},
	emits: ["explore-topics-menu-expanded"],
	data: function () {
		return {
			isOpen: false,
			tags: {},
		};
	},
	methods: {
		toggleDropdown() {
			this.isOpen = !this.isOpen;
		},
		closeToggleDropdown(event) {
            
			if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
				this.isOpen = false;
			}
		},
	},
	mounted() {
		document.addEventListener("click", this.closeToggleDropdown);
		if (this.contentStore?.tags) {
			this.tags = this.contentStore?.tags;
		}
		const { tags } = storeToRefs(this.contentStore);
		var self = this;
		watch(tags, function (newValue, oldValue) {
			self.tags = newValue;
		});
	},
	computed: {
		style() {
			let height = Object.keys(this.tags).length * 40 + 15;

			return {
				"--div-height": height + "px",
			};
		},
		isUserInternalAdmin() {
			return this.userStore?.userRole === OIDC.INTERNAL_ADMIN_ROLE;
		},
	},
	watch: {
		isOpen(isOpen) {
			this.$emit("explore-topics-menu-expanded", isOpen);
		},
		initialIsOpen(isOpen) {
			this.isOpen = isOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/navigation/chevron.scss";

.topics-menu:focus {
	outline: none;
}

li.item {
	display: block;

	&:hover {
		cursor: pointer;
	}

	> .chevron {
		&.collapsible {
			&.open {
				color: $red;
				font-weight: $font-weight-bold;
			}

			+ div {
				overflow: hidden;

				a {
					color: $grayscale-two;
					text-decoration: none;
				}
			}
		}
	}

	&::before {
		border-color: #525656;
	}

	&::after {
		border-color: transparent;
	}
}

@include tablet {
	.topics-menu {
		display: flex;
		align-items: center;
		padding: 0 $spacer-five;

		& > .item {
			padding: $spacer-five 0;
			border-bottom: 5px solid rgba($red, 0);
			width: auto;
		}

		.item:hover,
		.chevron.open {
			border-bottom-color: rgba($red, 1);
		}

		.chevron {
			padding-right: 25px;

			+ div {
				position: absolute;
				top: 111px;
				padding-left: $spacer-five;
				height: 0;

				.level-1 {
					opacity: 0;
					top: -10px;
					transition: 0.1s opacity ease-out 0.17s,
						0.2s top cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.05s;
				}
			}

			&.open {
				+ div {
					background-color: $grayscale-eight;
					width: 250px;
					box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
					height: auto;
					max-height: none;
					z-index: 1;

					.level-1 {
						padding-bottom: 0;
						top: 0;
						opacity: 1;

						.item {
							padding-top: 3px;

							&:first-child {
								padding-top: 14px;
							}

							&:last-child {
								padding-bottom: 14px;
							}

							& > * {
								border-bottom: 2px solid rgba($red, 0);
								padding-bottom: 4px;
								padding-top: 6px;
								display: inline-block;

								&:hover {
									border-bottom-color: rgba($red, 1);
									transition: $transition-length all linear;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $break-point-tablet + 1) and (max-width: $break-point-desktop - 1) {
	.topics-menu {
		padding: 0 $spacer-three;
	}
}

@include wide-mobile {
	ul.topics-menu {
		position: relative !important;
		border-bottom: 1px solid $grayscale-five;

		& > .item {
			padding-right: $spacer-five;

			> span {
				background: linear-gradient(
					to right,
					$primary,
					$primary 5px,
					transparent 5px,
					transparent
				);
				background-size: 100% 0;
				background-repeat: no-repeat;

				&.open {
					background-size: 100% 100%;
				}

				+ div {
					// max-height: 0;
					max-height: none;
					height: 0;
					// transition: 0.2s max-height cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
					transition: 0.2s height linear 0s;
					// 0.2s top cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.05s
					// transition: max-height $transition-length * 2 cubic-bezier(0.67, 0.9, 0.76, 0.37) 0s;
				}
			}

			.open + div {
				overflow: visible;
				height: var(--div-height);
				// max-height: 250px;

				.level-1 {
					> li {
						left: 0;
						opacity: 1;

						&:first-child {
							padding-top: $spacer-three;
						}
					}
				}
			}
		}

		.collapsible {
			padding: $spacer-five $spacer-four;
			display: block;

			+ div {
				li {
					opacity: 0;
					height: 0;
					max-height: 0;
					padding: 0 $spacer-five $spacer-four;

					&:last-child {
						padding-bottom: $spacer-five;
					}
				}
			}

			&.open {
				+ div {
					.level-1 {
						> li {
							height: auto;
							max-height: 99999px;
							padding: 0 $spacer-five $spacer-four;

							&:last-child {
								padding-bottom: $spacer-five;
							}

							@for $i from 1 through 10 {
								&:nth-child(#{$i}) {
									transition: $transition-length opacity ease-out $i * 0.08s;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
