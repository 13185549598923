<template>
<footer class="site-footer" v-if="showSiteFooter">
    <div class="footer-nav-wrapper">
        <SiteFooterPlainNavigationSection />
        <SiteFooterNavigationSection v-for="(footerNavSection, i) in footerNavSections" :key="i" :footerNavSection="footerNavSection" />
    </div>
    <form class="search-container">
        <SearchInput class="full-width-mobile" @search="search" />
    </form>
</footer>
</template>

<script>
import {
    contentSiteConsts,
    navigationScenarioConstants
} from "@/constants.js"
import SiteFooterPlainNavigationSection from "./SiteFooterPlainNavigationSection.vue";
import SiteFooterNavigationSection from "./SiteFooterNavigationSection.vue";
import SearchInput from "@/sharedcomponents/Inputs/SearchInput.vue";

export default {
    name: "site-footer-navigation",
    components: {
        SiteFooterPlainNavigationSection,
        SiteFooterNavigationSection,
        SearchInput
    },
    props: {
        tags: Object
    },
    data: function () {
        return {
            showSiteFooter: true,
            footerNavSections: [{
                    title: "FOR AGENTS",
                    navigation: [{
                            text: "Create an account",
                            navigationScenario: navigationScenarioConstants.NAVIGATE_TO_FIND_MY_AGENCY,
                        },
                        {
                            text: "Agent dashboard",
                            navigationScenario: navigationScenarioConstants.NAVIGATE_TO_AGENT_DASHBOARD,
                        },
                        {
                            text: "File a claim",
                            navigationScenario: navigationScenarioConstants.NAVIGATE_TO_FILE_CLAIM,
                        },
                        {
                            text: "Manage my account",
                            navigationScenario: navigationScenarioConstants.NAVIGATE_TO_USER_DETAILS,
                        },
                    ],
                },
                {
                    title: "EXPLORE TOPICS",
                    navigation: [],
                },
                {
                    title: "WHY SAFELITE",
                    navigation: [{
                            text: "Why choose Safelite",
                            destination: contentSiteConsts.WHY_CHOOSE_SAFELITE,
                            target: "_blank",
                        },
                        {
                            text: "Nationwide warranty",
                            destination: contentSiteConsts.NATIONWIDE_WARRANTY,
                            target: "_blank",
                        },
                        {
                            text: "Mobile and in-shop",
                            destination: contentSiteConsts.MOBILE_AUTO_GLASS_REPAIR,
                            target: "_blank",
                        },
                        {
                            text: "Customer reviews",
                            destination: contentSiteConsts.CUSTOMER_REVIEWS,
                            target: "_blank",
                        },
                        {
                            text: "Glass recycling",
                            destination: contentSiteConsts.RECYCLING,
                            target: "_blank",
                        },
                    ],
                },
                {
                    title: "OUR COMPANY",
                    navigation: [{
                            text: "About Safelite",
                            destination: contentSiteConsts.ABOUT_SAFELITE,
                            target: "_blank",
                        },
                        {
                            text: "Our leaders",
                            destination: contentSiteConsts.OUR_LEADERS,
                            target: "_blank",
                        },
                        {
                            text: "Press releases",
                            destination: contentSiteConsts.PRESS_RELEASES,
                            target: "_blank",
                        },
                        {
                            text: "Safelite Foundation",
                            destination: contentSiteConsts.SAFELITE_FOUNDATION,
                            target: "_blank",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        search(searchTerm) {
            this.$router.push({ name: "Search", query: { searchTerm } })
        },
        populateExploreTopicsSection(tags) {
            let navigation = [];
            const sortedValues = Object.values(tags).sort();
            for (let i = 0; i < sortedValues.length; i++) {
                let tagValue = sortedValues[i];
                navigation.push({
                    text: tagValue,
                    toTaggedArticleList: true,
                    tagKey: Object.keys(tags).filter(key => tags[key] === tagValue)[0],
                    tagValue: tagValue,
                })
            }
            this.footerNavSections.filter(section => section.title == "EXPLORE TOPICS")[0].navigation = navigation;
        }
    },
    watch: {
        tags(tags) {
            this.populateExploreTopicsSection(tags);
        }
    },
    mounted() {
        this.populateExploreTopicsSection(this.tags);
    }
};
</script>

<style lang="scss" scoped>
.site-footer {
    border-top: 1px solid #d4d6d8;
    border-bottom: 1px solid #d4d6d8;

    .footer-nav-wrapper {
        .general-nav {
            a,
            span {
                color: $grayscale-two;
                text-decoration: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }
}

@include tablet {
    .site-footer {
        .footer-nav-wrapper {
            max-width: $page-width-padded;
            padding: 50px $page-padding $spacer-four;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
        }
    }

    .search-container {
        max-width: $page-width-padded;
        margin: auto;
        padding: 0 $page-padding 40px;
    }
}

@include wide-mobile {
    .site-footer {
        .search-container {
            border-top: $divider-primary;
            padding: $spacer-six $spacer-four;
        }
    }
}
</style>
