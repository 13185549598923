import { defineStore } from 'pinia'
import httpClient from '@/http-client.js';
import {
    contentSiteApiConsts
} from "@/constants.js";

export const useContentStore = defineStore('content', {
    state: () => ({
        tags: {},
    }),
    persist: true,
    getters: {
    },
    actions: {
        loadTagsAsync(args) {
            const asyncPromise = httpClient.callHttpClient({
                method: "GET",
                endpoint: contentSiteApiConsts.GET_TAG_LIST_ENDPOINT,
            }).then(response => {
                this.tags = response.data.Result;
            });
            return asyncPromise;
        },
        getArticleWidgetsAsync(args) {
            return httpClient.callHttpClient({
              method: "GET",
              endpoint: contentSiteApiConsts.PAGE_CONTENTS_ENDPOINT + args.endpoint
            })
        },
        getArticleListAsync(args) {
            const tagQuery = args.query != null ? `?tagName=${args.query.tag}` : "";
            return httpClient.callHttpClient({
              method: "GET",
              endpoint: contentSiteApiConsts.GET_ARTICLE_LIST_ENDPOINT + tagQuery,
            })
        },
        getSearchResultsAsync(args) {
            return httpClient.callHttpClient({
              method: "GET",
              endpoint: contentSiteApiConsts.GET_SEARCH_RESULTS + args.SearchTerm
            })
        },
    },
})
